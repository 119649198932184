import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3a3eff7a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=3a3eff7a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CookieConsent: require('/~/src/components/CookieConsent.vue').default,Chat: require('/~/src/components/Chat.vue').default,Navigation: require('/~/src/modules/opus-ecom/components/Navigation.vue').default,HanselStoreSwitcherDialog: require('/~/src/modules/opus-ecom/components/HanselStoreSwitcherDialog.vue').default,Footer: require('/~/src/modules/opus-ecom/components/Footer.vue').default})
